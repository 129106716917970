import { NgModule } from '@angular/core';
import { IonicStorageModule } from '@ionic/Storage';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
//import { HomePageModule } from './home/home.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { Clipboard } from '@ionic-native/clipboard/ngx';

import { FormsModule } from '@angular/forms';

import { FeedbackComponent } from 'src/app/feedback/feedback.component'

import { EditComponent } from 'src/app/credit-detail/edit/edit.component';



import {
  Idfa,
  AdvertisingInfoResponse,
} from '@sparkfabrik/capacitor-plugin-idfa';


  // Get advertising id.
Idfa.getAdvertisingInfo()
.then((response: AdvertisingInfoResponse) => {
  if (response.isAdTrackingLimited === true) {
    console.log('Ads tracking not allowed by user.');
  }
  console.log("response id: " + response.id);
})
.catch((err: Error) => {
  console.error(err);
}); 



@NgModule({
  declarations: [AppComponent, EditComponent, FeedbackComponent],
  entryComponents: [EditComponent, FeedbackComponent],
  imports: [BrowserModule, 
  HttpClientModule,
  FormsModule,
  IonicModule.forRoot(), 
  AppRoutingModule, 
  IonicStorageModule.forRoot({
    name: '__mydb',
       driverOrder: ['sqlite', 'websql',  'localstorage']
  })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    Clipboard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
