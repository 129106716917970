import { Injectable } from '@angular/core';
import { Coaster } from './models.model';
import { Credit } from './models.model'; 
import { settings } from './models.model'; 
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import  { Clipboard }  from '@ionic-native/clipboard/ngx';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Subject } from 'rxjs';

import { Storage } from '@ionic/Storage';

import { AdMobPlus, BannerAd, InterstitialAd, RewardedAd} from '@admob-plus/capacitor';


@Injectable({
  providedIn: 'root'
})
export class CoastersService {

  public database: Coaster[];

  public credit_list: Credit[] = [
  ];
  
  public preload: Credit[] = []

  public coasters: Coaster[] = []

  public settings: settings;
  public defaultMetric: boolean;
  public selectedCredit: Credit;

  nativeAds: string[];

  constructor(
  private alertCtrl: AlertController,
  private http: HttpClient,
  private clipboard: Clipboard,
  private storage: Storage
  ) {
    this.defaultMetric = false
    this.settings = {units: "metric"}
    this.setAds();
  }

  setAds() {
    this.adRequest().subscribe(obj => {
      let tempArray = []
      for(var i in obj["natives"]) {
        tempArray.unshift(obj["natives"][i]);
      }
      this.nativeAds = tempArray;

      if(obj["banners"]) {
        this.showBanner()
      }
    
    })
  }

  adRequest() {
    let httpHeaders = new HttpHeaders({
      'accept': 'application/json'
    })

    let options = {
        headers: httpHeaders
    }

    return this.http.get("https://server.coasterbuddy.app/api/advertisements")
  }

  showBanner() {
    AdMobPlus.requestTrackingAuthorization().catch(e => console.log(e))

    const banner = new BannerAd({
      adUnitId: 'ca-app-pub-8387175987712880/8205603241', //real: ca-app-pub-8387175987712880/8205603241 test: ca-app-pub-3940256099942544/2934735716
      position: 'bottom',
    })

    document.addEventListener('admob.banner.size', async (event) => {
      event["size"].height
      window.resizeTo(window.innerWidth - event["size"].width, window.innerHeight - event["size"].height);
    })

    banner.load().then(async () => {
      await banner.show()
    })
  }

  async setUserData() {
    await Filesystem.writeFile({
      path: 'userdata.json',
      data: JSON.stringify({new: false, developer: false, settings: this.settings}),
      encoding: Encoding.UTF8,
      directory: Directory.Documents
    });
    return Promise.resolve()
  }

  copy() {
    this.clipboard.copy("String(this.database)")
    console.log("Copied")
  }

  pushCredit(coaster) {

    let tempObj = coaster;
    tempObj.tally = 1;
    tempObj.time = new Date().toISOString()

    this.credit_list.unshift(tempObj);

    console.log(coaster.materialType)
   }


  makeRequest(name, park, manufacturer, status) {
    this.database = []
    this.getCoasterData("", name, park, manufacturer, status).subscribe(obj => {
      console.log(obj)
      Array.prototype.push.apply(this.database,obj);
    })
  }

  getCoasterData(general, name, park, manufacturer, status) {
    let coasterData = "https://server.coasterbuddy.app/api/coasters?"

    if (general && general.trim() != "") {
      coasterData+= "general=" + general + "&"
    }
    if (name && name.trim() != "") {
      coasterData+= "name=" + name + "&"
    }
    if (park && park.trim() != "") {
      coasterData+= "park=" + park + "&"
    }
    if (manufacturer && manufacturer.trim() != "") {
      coasterData+= "manufacturer=" + manufacturer + "&"
    }
    if (status != "all") {
      coasterData+= "status=" + status
    }

    let httpHeaders = new HttpHeaders({
      'accept': 'application/json',
    })

    let options = {
        headers: httpHeaders
    }

    this.http.post(coasterData, null, options)
    return this.http.get(coasterData, options)
  }

  makeIdRequest(id) {
    let coasterData = "https://server.coasterbuddy.app/api/coasters/" + id;
    let httpHeaders = new HttpHeaders({
      'accept': 'application/json',
    })

    let options = {
        headers: httpHeaders
    }

    return this.http.get(coasterData, options)
  }

  getCoasterDataFromPark(name) {
    let parkData = "https://server.coasterbuddy.app/api/coasters?park=" + name
    let httpHeaders = new HttpHeaders({
      'accept': 'application/json',
    })

    let options = {
        headers: httpHeaders
    }

    this.http.post(parkData, null, options)
    return this.http.get(parkData, options)
  }

  async convertCredits() {
    let convertedList = [];
    let remainderList = [];
    let url = "https://server.coasterbuddy.app/convert"
    let httpHeaders = new HttpHeaders({
      'accept': 'application/json',
    })
    let options = {
      headers: httpHeaders
    }
  
    return new Promise((resolve, reject) => {
      this.storage.get("credits").then(obj => {
        if(obj != null) {
          this.http.post(url, obj).subscribe(resp => {
            convertedList = resp["converted"]
            remainderList = resp["remainder"]
            resolve([convertedList, remainderList]) // resolve with the result
          }, error => {
            reject(error); // reject with the error
          })
        }
      })
    });
  }
  



/*
getCoasterData() {
    let parkData = "https://server.coasterbuddy.app/api/coasters"
    let httpHeaders = new HttpHeaders({
      'accept': 'application/json'
    })

    let options = {
        headers: httpHeaders
    }

    this.http.post(parkData, null, options)
    return this.http.get(parkData, options)
  }
*/





  

  getAllCoasters() {
 	return [...this.coasters];
  };
  getAllCredits() {
return [...this.credit_list];
  };

  getCredit(creditid: number) {
    return {
        ...this.credit_list.find(credit => {
            return credit.id === creditid;
        })
     };
  }

deleteCoaster(coasterId: number) {
    this.credit_list.splice(this.credit_list.findIndex(i => i.id === coasterId), 1);
    //this.credit_list = this.getAllCredits();
    this.setData();

};
async setData() {
  await Filesystem.writeFile({
      path: 'credits.json',
      data: JSON.stringify(this.credit_list),
      encoding: Encoding.UTF8,
      directory: Directory.Documents
    });
};
clearData() {
  this.credit_list = []
    this.setData();
  };

  getCoasterImage(value) {
    if (value != null) {
      return 'https://pictures.captaincoaster.com/280x210/' + value.path.substring(2)
    }
    else {
      return ""
    }
  }


coasterInCredits(ID) {
    for (var i = 0; i < this.credit_list.length; i++) {
        if (this.credit_list[i].id == ID) {
            return true
        }
    }
    return false;
}

tallyCredit(ID) {
    //get specific credit using "ID"
    var credit = this.credit_list.find(credit => {
            return credit.id === ID;
        })

    //add tally to selcted credit
    this.credit_list[this.credit_list.indexOf(credit)].tally++;
    }

    onDeleteCoaster(coasterId) {
        this.alertCtrl.create({
            header: 'Are You Sure?',
            message: 'Do you want to delete ' + this.getCredit(coasterId).name + '?',
            buttons: [
            {
              text: 'Cancel',
              role: 'cancel'
            },
            {
              text: 'Delete',
              handler: () => {
               this.deleteCoaster(coasterId);
            }
           }
          ]
        })
        .then(alertEl => {
            alertEl.present();
        });
        }

        //stats functions

  sortTime() {

    var list = [];

    for (var i = 0; i < this.credit_list.length; i++) {

    if (list.length == 0) {
      list.push(this.credit_list[i])
      }
      else if (Date.parse(this.credit_list[i].time) < Date.parse(list[list.length -1].time)) {
        list.push(this.credit_list[i])
      }
      else {
      for (var j = 0; j < list.length; j++) {
        if (Date.parse(list[j].time) < Date.parse(this.credit_list[i].time)) {
          list.splice(j, 0, this.credit_list[i])
          break;   
        }
      }        
      }
    }
    return list;
  }
  
  findVisitedParks() {
  var tempList = [];
  
  // if park is not in tempList, push it
  for (var i = 0; i <this.credit_list.length; i++) {
  if (tempList.includes(this.credit_list[i].park)!=true) {
  tempList.push(this.credit_list[i].park)
    }
  }
return tempList.length
  }

  findVisitedMans() {
    var tempList = [];
    
    // if park is not in tempList, push it
    for (var i = 0; i <this.credit_list.length; i++) {
    if (tempList.includes(this.credit_list[i].manufacturer)!=true) {
    tempList.push(this.credit_list[i].manufacturer)
      }
    }
  return tempList.length
    }

      findVisitedWood() {
        var amount = 0;
        
        // if park is not in tempList, push it
        for (var i = 0; i <this.credit_list.length; i++) {
        if (this.credit_list[i].materialType.name == "Wood") {
          amount++;
          }
        }
      return amount
        }

        findVisitedSteel() {
          var amount = 0;
          
          // if park is not in tempList, push it
          for (var i = 0; i <this.credit_list.length; i++) {
          if (this.credit_list[i].materialType.name == "Steel") {
            amount++;
            }
          }
        return amount
          }

    findTallestCoaster() {
      var tempList = [];
      
      // if park is not in tempList, push it
      for (var i = 0; i < this.credit_list.length; i++) {
      if (tempList[0] == null || this.credit_list[i].height > tempList[tempList.length -1].height) {
          tempList.push(this.credit_list[i])
        }
      }
      console.log();
    return tempList[tempList.length -1]
      }

      findTallyCoaster() {
        var tempList = [];
        
        // if park is not in tempList, push it
        for (var i = 0; i < this.credit_list.length; i++) {
        if (tempList[0] == null || this.credit_list[i].tally > tempList[tempList.length -1].tally) {
            tempList.push(this.credit_list[i])
          }
        }
        console.log();
      return tempList[tempList.length -1]
        }

  set(coaster) {
    this.credit_list.splice(this.credit_list.findIndex(i => i.id === coaster.id), 1, coaster);
    this.setData()
  }

  manufacturersInCredits() {
    let tempList = [];
    for (let i = 0; i < this.credit_list.length; i++) {
      if (typeof this.credit_list[i].manufacturer != "undefined" && this.credit_list[i].manufacturer != null) {
        if (!tempList.includes(this.credit_list[i].manufacturer.name)) {
          tempList.push(this.credit_list[i].manufacturer.name)
        }
      }
    }
    return tempList;
  }

  parksInCredits() {
    let tempList = [];
    for (let i = 0; i < this.credit_list.length; i++) {
      if (typeof this.credit_list[i].park != "undefined" && this.credit_list[i].park != null) {
        if (!tempList.includes(this.credit_list[i].park.name)) {
          tempList.push(this.credit_list[i].park.name)
        }
      }
    }
    return tempList;
  }

  typesInCredits() {
    let tempList = [];
    for (let i = 0; i < this.credit_list.length; i++) {
      if (typeof this.credit_list[i].seatingType != "undefined" && this.credit_list[i].seatingType != null) {
        if (!tempList.includes(this.credit_list[i].seatingType.name)) {
          tempList.push(this.credit_list[i].seatingType.name)
        }
      }
    }
    return tempList;
  }

  async getData() {

    const contents = await Filesystem.readFile({
      path: 'credits.json',
      encoding: Encoding.UTF8,
      directory: Directory.Documents,
    });
    this.credit_list = JSON.parse(contents.data)
    
    return Promise.resolve
  }
}
