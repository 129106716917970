import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams} from '@ionic/angular';
import { Credit } from 'src/app/models.model';
import { CoastersService } from 'src/app/coasters.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit {

  public loadedCredit: Credit;
  public inputCredit: Credit;

  public displaySpeed: number;
  public displayHeight: number;
  public displayLength: number;

  constructor(
    private modalController: ModalController,
    private coastersService: CoastersService,
  ) { 

    this.inputCredit = {
      height:NaN,
      id:NaN,
      inversionsNumber:NaN,
      length:NaN,
      mainImage:{path: ""},
      manufacturer:{name: ""},
      materialType:{name: ""},
      name:"",
      park:{name: ""},
      rank:NaN,
      score:"",
      seatingType:{name: ""},
      speed:NaN,
      status:{name: ""},
      totalRatings:NaN,
      validDuels:NaN
    }
    this.inputCredit.tally = NaN
    
    
    this.loadedCredit = this.coastersService.selectedCredit

    this.inputCredit.materialType.name = this.loadedCredit.materialType.name

    this.displaySpeed = this.loadedCredit.speed;
    this.displayHeight = this.loadedCredit.height;
    this.displayLength = this.loadedCredit.length;

    //convert units
    if (this.coastersService.settings.units == "imperial") {
      this.displaySpeed = Math.round(this.displaySpeed * 0.621371);
      this.displayHeight = Math.round(this.displayHeight * 3.280839895);
      this.displayLength = Math.round(this.displayLength * 3.280839895);
    }
    console.log(this.loadedCredit)
  }

  ngOnInit() {}

  closeModal() { 
    this.modalController.dismiss();
  }

  changeCoaster() {
    if (this.inputCredit.name != "") {
      this.loadedCredit.name = this.inputCredit.name
    }
    if (!Number.isNaN(this.inputCredit.height)) {
      if (this.coastersService.settings.units == "imperial") {
        this.inputCredit.height = this.inputCredit.height / 3.280839895
      }
      this.loadedCredit.height = this.inputCredit.height
    }
    if (!Number.isNaN(this.inputCredit.inversionsNumber)) {
      this.loadedCredit.inversionsNumber = this.inputCredit.inversionsNumber
    }
    if (!Number.isNaN(this.inputCredit.length)) {
      if (this.coastersService.settings.units == "imperial") {
        this.inputCredit.length = this.inputCredit.length / 3.280839895
      }
      this.loadedCredit.length = this.inputCredit.length
    }
    if (this.inputCredit.mainImage.path != "") {
      this.loadedCredit.mainImage.path = this.inputCredit.mainImage.path
    }
    if (this.inputCredit.manufacturer.name != "") {
      this.loadedCredit.manufacturer.name = this.inputCredit.manufacturer.name
    }
    if (this.inputCredit.materialType.name != this.loadedCredit.materialType.name) {
      this.loadedCredit.materialType.name = this.inputCredit.materialType.name
    }
    if (this.inputCredit.park.name != "") {
      this.loadedCredit.park.name = this.inputCredit.park.name
    }
    if (this.inputCredit.seatingType.name != "") {
      this.loadedCredit.seatingType.name = this.inputCredit.seatingType.name
    }
    if (!Number.isNaN(this.inputCredit.speed)) {
      if (this.coastersService.settings.units == "imperial") {
        this.inputCredit.speed = Math.round(this.inputCredit.speed / 0.621371)
      }
      this.loadedCredit.speed = this.inputCredit.speed
    }
    if (this.inputCredit.time != undefined) {
      this.loadedCredit.time = this.inputCredit.time
      console.log(this.inputCredit.time)
    }
    if (!Number.isNaN(this.inputCredit.tally)) {
      this.loadedCredit.tally = this.inputCredit.tally
    }
  }

  resetValues() {

    this.coastersService.makeIdRequest(this.loadedCredit.id).subscribe(obj => {
      this.coastersService.set(obj)
    })
    this.closeModal();
  }

  submit() {
    this.changeCoaster()
    this.coastersService.set(this.loadedCredit);
    this.closeModal();
  }

}
